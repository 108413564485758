import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import axios from 'axios'
import VueAxios from 'vue-axios'

import mitt from 'mitt';
const $bus = mitt();

require('./assets/fa/css/all.css');
require('bootstrap/dist/js/bootstrap.js');

var queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const token = urlParams.get('t');

const app = createApp(App);
app.config.globalProperties.$bus = $bus;
app.config.globalProperties.accessToken = token;
app.config.unwrapInjectedRef = true
app.use(router).use(VueAxios, axios).mount("#kt_body");
