import { createRouter, createWebHistory } from "vue-router";
import Docs from '../views/Docs.vue';

const routes = [
  {
    path: "/",
    name: "Docs",
    component: Docs,
  },
  {
    path: "/dashboard",
    component: () =>
      import("@/views/OrganisationDashboard.vue"),
      children: [
        {
          path: '',
          name: "OrganisationDashboard",
          component: () => import ('@/views/ClientDashboard.vue'),
        },
        {
          path: 'apps',
          component: () => import ('@/views/Apps.vue'),
        },
        {
          path: "apps/:appid([a-z0-9-]+)",
          name: "AppsDetails",
          props: true,
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import("../views/AppsDetails.vue"),
        },
        {
          path: 'users',
          component: () => import ('@/views/Users.vue'),
        },
        {
          path: "users/:userId([a-z0-9-]+)",
          name: "UsersDetails",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import("@/views/UsersDetails.vue"),
        },
        {
          path: "users/add",
          name: "UsersDetailsNew",
          props: true,
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import("@/views/UsersAdd.vue"),
        },
        {
          path: "apps/add",
          name: "AppsAdd",
          props: true,
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import("@/views/AppsAdd.vue"),
        }
      ]
  },
  {
    path: "/admin",
    component: () =>
      import("@/views/AdminDashboard.vue"),
      children: [
        {
          path: 'dashboard',
          alias: '',
          name: "AdminDashboard",
          component: () => import ('@/views/Empty.vue'),
        },
        {
          path: 'requests',
          component: () => import ('@/views/AdminRequests.vue'),
        },
        {
          path: 'requests/:id([a-z0-9-]+)',
          props: true,
          component: () => import ('@/components/APIRequest.vue'),
        },
        {
          path: "organisations",
          name: "AdminOrganisations",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import("@/views/AdminOrganisations.vue"),
        },
        {
          path: "stats",
          name: "Stats",
          component: () =>
            import("@/views/Stats.vue"),
        }
      ]
  },
  /*
  {
    path: '/admin/organisations/:id([a-z0-9-]+)',
    props: true,
    component: () =>
      import('@/views/OrganisationDashboard.vue'),
      children: [
        {
          path: '',
          component: () => import ('@/views/ClientDashboard.vue'),
        },
        {
          path: 'apps',
          component: () => import ('@/views/Apps.vue'),
        },
        {
          path: "apps/:appid([a-z0-9-]+)",
          name: "AdminAppsDetails",
          props: true,
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import("@/views/Test.vue"),
        }
      ]
  },*/
  {
    path: "/admin/organisations/:id([a-z0-9-]+)",
    name: "AdminOrganisationDashboard",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("@/views/AdminClientDashboard.vue"),
  },
  {
    path: "/admin/organisations/:id([a-z0-9-]+)/apps",
    name: "AdminApps",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("@/views/AdminApps.vue"),
  },
  {
    path: "/admin/organisations/:id([a-z0-9-]+)/apps/:appid([a-z0-9-]+)",
    name: "AdminAppsDetails",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("@/views/AdminAppsDetails.vue"),
  },
  {
    path: "/admin/organisations/:id([a-z0-9-]+)/users",
    name: "AdminUsers",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("@/views/AdminUsers.vue"),
  },
  {
    path: "/admin/organisations/:id([a-z0-9-]+)/users/:userId([a-z0-9-]+)",
    name: "AdminUsersDetails",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("@/views/AdminUsersDetails.vue"),
  },
  {
    path: "/admin/organisations/:id([a-z0-9-]+)/users/add",
    name: "AdminUsersDetailsNew",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("@/views/AdminUsersAdd.vue"),
  },
  {
    path: '/admin/orrganisations/:id([a-z0-9-]+)/apps/:appid([a-z0-9-]+)',
          name: "AppssDetails",
          props: true,
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import("@/views/Test.vue"),
  },
  {
    path: "/admin/organisations/:id([a-z0-9-]+)/apps/add",
    name: "AdminAppsAdd",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("@/views/AdminAppsAdd.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
