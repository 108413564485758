<template>
  <!-- eslint-disable -->
  <TopBar ref="topbar" />
  <API ref="api" @login="login" @logout="logout" v-show="$route.name != 'Docs'" />
  <div v-if="$route.name == 'Docs'" class="d-flex flex-column flex-root">
    <router-view ref="view" />
  </div>
  <div v-else-if="logged && profile && !profile.is_admin && (!profile.organisation || (organisation && !organisation.is_accepted))">
    <APIRequest />
  </div>
  <div v-else-if="$route.name != 'Docs' && logged" class="d-flex flex-column flex-root">
		<router-view ref="viewProfile" />
	</div>
  <div class="modal fade" tabindex="-1" id="modalRODO" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-850px">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="fw-bolder">Informacja RODO</h2>
          <div class="btn btn-icon btn-sm btn-active-icon-primary btn-close" data-bs-dismiss="modal" aria-label="Close">
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
              </svg>
            </span>
          </div>
        </div>
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <p>Informacja o przetwarzaniu danych osobowych w serwisie</p>
          <p class="fw-bolder">I. Cel przetwarzania danych</p>
          <p>Główny Inspektor Nadzoru Budowlanego (GINB), jako administrator danych osobowych, zbiera i przetwarza Państwa dane osobowe w celu umożliwienia wydania klucza publicznego i implementacji systemu teleinformatycznego Rejestru Wniosków, Decyzji i Zgłoszeń.</p>
          <p class="fw-bolder">II. Podstawa prawna przetwarzania</p>
          <p>Podstawą prawną przetwarzania jest konieczność realizacji obowiązku wynikającego z przepisów (art. 6 ust. 1 lit. c RODO) lub w związku z wykonywaniem przez administratora danych zadań realizowanych w interesie publicznym lub w ramach sprawowania władzy publicznej powierzonej administratorowi (art. 6 ust. 1 lit. e RODO).</p>
          <p class="fw-bolder">III. Okres przechowywania danych</p>
          <p>Dane osobowe będą przechowywane przez okres niezbędny do realizacji celów przetwarzania, lecz nie krócej niż okres wskazany w przepisach o archiwizacji.</p>
          <p class="fw-bolder">V. Obowiązek podania danych</p>
          <p>Podanie danych osobowych w serwisie jest dobrowolne.</p>
          <p class="fw-bolder">VI. Dostęp do danych osobowych</p>
          <p>Dostęp do Państwa danych osobowych mają pracownicy Głównego Urzędu Nadzoru Budowlanego. Ponadto Państwa dane osobowe mogą być/są powierzane lub udostępniane podmiotom świadczącym na rzecz Głównego Urzędu Nadzoru Budowlanego usługi związane z obsługą i rozwojem systemów teleinformatycznych oraz zapewnieniem łączności, w szczególności dostawcy rozwiązań IT i operatorzy telekomunikacyjni.</p>
          <p class="fw-bolder">VII. Prawa osób, których dane dotyczą</p>
          <p>Przysługują Państwu następujące prawa:</p>
          <ol>
          <li>Prawo dostępu do danych osobowych i ich sprostowania</li>
          <li>W przypadku, gdy przetwarzane dane okażą się nieaktualne, osoba, której dane dotyczą może zwrócić się do GINB z wnioskiem o ich aktualizację;</li>
          <li>Prawo usunięcia lub ograniczenia ich przetwarzania – jeżeli spełnione są przesłanki określone w art. 17 i 18 RODO.</li>
          <li>Prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</li>
          </ol>
          <p class="fw-bolder">VIII. Zautomatyzowane podejmowanie decyzji</p>
          <p>Państwa dane osobowe nie podlegają zautomatyzowanemu podejmowaniu decyzji, w tym profilowaniu.</p>
          <p class="fw-bolder">IX. Kontakt z Inspektorem Ochrony Danych</p>
          <p>Główny Inspektor Nadzoru Budowlanego ma swoją siedzibę pod adresem ul. Krucza 38/42, 00-926 Warszawa.</p>
          <p>W przypadku pytań, kontakt z Inspektorem Ochrony Danych wyznaczonym przez GINB jest możliwy pod adresem:</p>
          <ul>
          <li>ul. Krucza 38/41, 00-926 Warszawa,</li>
          <li>poczty elektronicznej: iod@gunb.gov.pl,</li>
          <li>skrzynki ePUAP Głównego Urzędu Nadzoru Budowlanego /GUNB/skrytka</li>
          </ul>
          <div class="float-right d-none">
            <button class="btn btn-primary mt-5" data-bs-dismiss="modal" aria-label="Close">Zamknij</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import API from '@/components/API.vue';
import APIRequest from '@/components/APIRequest.vue';

export default {
  name: 'App',
  data() {
    return {
      profile: false,
      logged: false,
      isDashboard: false,
      organisation: {}
    }
  },
  provide() {
    return {
      $profile: () => this.profile,
      $logged: () => this.logged,
      $organisation: () => this.organisation,
    }
  },
  components: {
    TopBar,
    API,
    APIRequest
  },
  methods: {
    login(profile) {
      this.logged = true;
      this.profile = profile;
      this.getMyOrganisation();
    },
    logout() {
      this.logged = false;
      this.profile = false;
    },
    getMyOrganisation: function() {
      var self = this;
      if (!this.profile)
      {
        return;
      }
      this.$refs.api.get('/me/organisation').then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.organisation = response.data;
        }
      })
      .catch(function () {
        self.organisation = {};
        self.updateProfile();
      });
    },
    updateProfile: function() {
      var self = this;
      this.$refs.api.get('/me').then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.profile = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
    this.$bus.off('update-organisation');
    this.$bus.off('update-profile');
    this.$bus.off('update-profile-and-organisation');
  },
  created () {
  },
  mounted() {
    this.$bus.on('update-profile', () => {
      this.updateProfile();
    })
    this.$bus.on('update-organisation', () => {
      this.getMyOrganisation();
      clearInterval(this.timer);
      this.timer = setInterval(this.getMyOrganisation, 5000);
    })
    this.$bus.on('update-profile-and-organisation', () => {
      this.updateProfile();
      this.getMyOrganisation();
      clearInterval(this.timer);
      this.timer = setInterval(this.getMyOrganisation, 5000);
    })
    this.$bus.on('start-organisation-updates', () => {
      clearInterval(this.timer);
      this.timer = setInterval(this.getMyOrganisation, 5000);
    })
    this.$bus.on('stop-organisation-updates', () => {
      clearInterval(this.timer);
    })
    this.updateProfile();
  }
};
</script>

<style lang="scss">
@import "./assets/styles/ceres/style.scss";
@import "./assets/styles/portal.scss";
</style>
