<template>
  <div class="container-xxl d-flex flex-stack flex-wrap">
    <div :class="profile.is_admin ? 'flex-lg-row-fluid ms-lg-10' : 'd-flex align-items-center flex-wrap py-2 col-lg-10'">
      <h2 v-if="profile.is_admin" class="text-white mb-7">Wniosek o dostęp</h2>
      <h3 v-else class="text-white fw-bolder fs-2qx mt-12 mb-10">Wniosek o dostęp do API</h3>
      <div class="flex-lg-row-fluid">

      <div v-if="organisation.application_date && !profile.is_admin" class="alert alert-info d-flex align-items-center p-5 mb-10">
        <span class="svg-icon svg-icon-2hx svg-icon-info me-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
          </svg>
				</span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-info">Wniosek został wysłany</h4>
            <span>Trwa oczekiwanie na akceptację wniosku.</span>
          </div>
        </div>

        <div class="card mb-5 mb-xl-10">
          <div class="w-lg-700px pt-10 px-10">
            <div class="current">
              <div class="w-100">
                <div v-if="profile.is_admin">
                  <h2>{{ organisation.name }}</h2>
                  <p v-if="clientType == 'internal'" class="text-dark d-block fs-4 mb-2 pb-10">Organ AAB</p>
                  <p v-else-if="clientType == 'external'" class="text-dark d-block fs-4 mb-2 pb-10">Klient zewnętrzny</p>
                </div>
                <div v-else class="pb-10">
                  <h2 class="fw-bolder d-flex align-items-center text-dark">Wybierz rodzaj klienta API</h2>
                  <div class="text-muted fw-bold fs-6">Od rodzaju klienta zależy tryb wnioskowania oraz przyznane uprawnienia</div>
                </div>
                <div v-if="!profile.is_admin" class="fv-row">
                  <div class="row">
                    <div @click="clientType = 'internal'" class="col-lg-6">
                      <input type="radio" class="btn-check" :checked="clientType == 'internal'" />
                      <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10">
                        <span class="d-block fw-bold text-start">
                          <span class="text-dark fw-bolder d-block fs-4 mb-2">Organy AAB</span>
                          <span class="text-muted fw-bold fs-6">Przeznaczony dla organów administracji architektoniczno-budowlanej (AAB).</span>
                        </span>
                      </label>
                    </div>
                    <div @click="clientType = 'external'" class="col-lg-6">
                      <input type="radio" class="btn-check" :checked="clientType == 'external'" />
                      <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10">
                        <span class="d-block fw-bold text-start">
                          <span class="text-dark fw-bolder d-block fs-4 mb-2">Pozostali klienci</span>
                          <span class="text-muted fw-bold fs-6">Przeznaczony dla osób fizycznych, przedsiębiorstw oraz organizacji spoza struktury AAB.</span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5 mb-xl-10">
          <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Organizacja</h3>
            </div>
          </div>
          <div class="card mb-5 mb-xl-10">
            <div class="card-body border-top p-9">

              <div class="d-flex flex-wrap align-items-center fs-6 mb-12">
                <div>Podaj dane firmy lub organizacji jaką reprezentujesz. Jeśli występujesz jako osoba fizyczna, w polu nazwa podaj swoje imię i nazwisko.</div>
              </div>

              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Nazwa lub imię i nazwisko</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.name }" @change="errors.name = false" :disabled="organisation.application_date" class="form-control form-control-lg form-control-solid" v-model="organisation.name" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.name }}</div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Adres</label>
                <div class="col-lg-4 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.street }" @change="errors.street = false" :disabled="organisation.application_date" class="form-control form-control-lg form-control-solid" v-model="organisation.street" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.street }}</div>
                </div>
                <div class="col-lg-2 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.house_number }" @change="errors.house_number = false" :disabled="organisation.application_date" class="form-control form-control-lg form-control-solid" v-model="organisation.house_number" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.house_number }}</div>
                </div>
                <div class="col-lg-2 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.flat_number }" @change="errors.flat_number = false" :disabled="organisation.application_date" class="form-control form-control-lg form-control-solid" v-model="organisation.flat_number" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.flat_number }}</div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Kod pocztowy i miasto</label>
                <div class="col-lg-2 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.postcode }" @change="errors.postcode = false" :disabled="organisation.application_date" class="form-control form-control-lg form-control-solid" v-model="organisation.postcode" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.postcode }}</div>
                </div>
                <div class="col-lg-6 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.city }" @change="errors.city = false" :disabled="organisation.application_date" class="form-control form-control-lg form-control-solid" v-model="organisation.city" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.city }}</div>
                </div>
              </div>

              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Kraj</label>
                <div class="col-lg-5 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.country }" @change="errors.country = false" :disabled="organisation.application_date" class="form-control form-control-lg form-control-solid" v-model="organisation.country" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.country }}</div>
                </div>
              </div>

              <div v-if="profile.is_admin && clientType == 'internal'" class="row mb-6">
                <label class="required col-lg-4 col-form-label fw-bold fs-6">Wybierz organ</label>
                <div class="col-lg-8 fv-row">
                  <select v-model="organisation.authority" :disabled="organisation.application_date && !profile.is_admin" class="form-select form-select-solid form-select-lg" :class="profile.is_admin ? 'border-primary' : ''">
                    <option v-for="authority in authorities" v-bind:value="authority.id" :key="authority.id">{{ authority.nazwa }}</option>
                  </select>
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.authority }}</div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="card mb-5 mb-xl-10">
          <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Aplikacja</h3>
            </div>
          </div>
          <div class="card mb-5 mb-xl-10">
            <div class="card-body border-top p-9">

              <div class="d-flex flex-wrap align-items-center fs-6 mb-12">
                <div>Przedstaw informacje dotyczące wnioskowanej aplikacji, która będzie korzystać z RWDZ API.</div>
              </div>

              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Nazwa aplikacji</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.app_name }" @change="errors.app_name = false" :disabled="organisation.application_date" class="form-control form-control-lg form-control-solid" v-model="organisation.app_name" />
                  <div>Nazwa aplikacji służy celom jej identyfikacji w systemie i jest poglądowa.</div>
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.app_name }}</div>
                </div>
              </div>

              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Opis przeznaczenia aplikacji</label>
                <div class="col-lg-8 fv-row">
                  <textarea :class="{ 'is-invalid': errors.app_description }" @change="errors.app_description = false" class="form-control" rows="10" v-model="organisation.app_description" :disabled="organisation.application_date"></textarea>
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.app_description }}</div>
                </div>
              </div>

              <div class="row mb-6">
                <label class="required col-lg-4 col-form-label fw-bold fs-6">Wnioskowane uprawnienia</label>
                <div v-if="clientType == 'external'" class="col-lg-8 fv-row">
                  <select :class="{ 'is-invalid': errors.role, 'border-primary': profile.is_admin }" @change="errors.role = false" v-model="roleId" :disabled="organisation.application_date && !profile.is_admin" class="form-select form-select-solid form-select-lg">
                    <option v-for="role in rolesExternal" v-bind:value="role.id" :key="role.id">{{ role.name }}</option>
                  </select>
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.role }}</div>
                </div>
                <div v-else-if="clientType == 'internal'" class="col-lg-8 fv-row">
                  <select :class="{ 'is-invalid': errors.role, 'border-primary': profile.is_admin }" @change="errors.role = false" v-model="roleId" :disabled="organisation.application_date && !profile.is_admin" class="form-select form-select-solid form-select-lg">
                    <option v-for="role in rolesInternal" v-bind:value="role.id" :key="role.id">{{ role.name }}</option>
                  </select>
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.role }}</div>
                </div>
              </div>

              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Uzasadnienie wniosku</label>
                <div class="col-lg-8 fv-row">
                  <textarea :class="{ 'is-invalid': errors.request_justification }" @change="errors.request_justification = false" class="form-control" rows="15" v-model="organisation.request_justification" :disabled="organisation.application_date"></textarea>
                  <div>Opisz celowość wnioskowanego zakresu dostępu.</div>
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.request_justification }}</div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div v-if="clientType == 'internal'" class="alert alert-light d-flex align-items-center p-5 mb-10">
					<div class="d-flex flex-column">
						<h4 class="mb-1 text-ligth">Komunikat dla organów AAB</h4>
						<span>Wypełnienie niniejszego wniosku w systemie ma charakter pomocniczy. Po zapisaniu formularza prosimy o przekazanie wniosku formalnego na adres GUNB.</span>
					</div>
				</div>

        <div class="card mb-5 mb-xl-10">
          <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Dane kontaktowe</h3>
            </div>
          </div>
          <div class="card mb-5 mb-xl-10">
            <div v-if="profile.is_admin && organisation && organisation.users && organisation.users.length" class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label fw-bold fs-6">Imię i nazwisko</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" disabled="disabled" class="form-control form-control-lg form-control-solid" :value="organisation.users[0].first_name + ' ' + organisation.users[0].last_name" />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label fw-bold fs-6">E-mail</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" disabled="disabled" class="form-control form-control-lg form-control-solid" :value="organisation.users[0].email" />
                </div>
              </div>
            </div>
            <div v-else class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label fw-bold fs-6">Imię i nazwisko</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" disabled="disabled" class="form-control form-control-lg form-control-solid" :value="profile.first_name + ' ' + profile.last_name" />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label fw-bold fs-6">E-mail</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" disabled="disabled" class="form-control form-control-lg form-control-solid" :value="profile.email" />
                </div>
              </div>
            </div>

            <div v-if="profile.is_admin && organisation.application_date" class="card-footer d-flex py-6 px-9 justify-content-end">
              <button @click="deleteRequest" class="btn btn-danger mx-3" :disabled="requestSend">Usuń wniosek</button>
              <button @click="cancelRequest" class="btn btn-secondary mx-3" :disabled="requestSend">Zwróć wniosek</button>
                <button @click="acceptRequest" class="btn btn-success mx-3" :disabled="requestSend">Zaakceptuj wniosek</button>
            </div>
            <div v-else-if="!profile.is_admin && !organisation.application_date" class="card-footer d-flex justify-content-end py-6 px-9">
              <button @click="sendRequest" class="btn btn-primary" :disabled="requestSend">Wyślij wniosek</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'APIRequest',
  inject: ['$organisation', '$profile'],
  props: ['id'],
  data() {
    return {
      requestSend: false,
      roleId: false,
      rolesInternal: {},
      rolesExternal: {},
      authorities: [],
      org: {},
      clientType: 'external',
      errors: {}
    }
  },
  mounted() {
    this.reload();
  },
  methods: {
    reload: function() {
      var self = this;

      this.$root.$refs.api.get('roles?type=external').then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.rolesExternal = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });

      this.$root.$refs.api.get('roles?type=internal').then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.rolesInternal = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });

      this.$root.$refs.api.get('authorities').then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.authorities = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });

      if (this.profile.is_admin)
      {
        this.$root.$refs.api.get('organisations/' + this.id).then(function (response) {
          if (typeof response.data !== 'undefined')
          {
            self.org = response.data;
            self.roleId = response.data.role;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
    sendRequest: function() {
      var self = this;
      this.requestSend = true;
      this.errors = {};
      var params = self.organisation;
      params['type'] = self.clientType;
      params['role'] = self.roleId;
      this.$root.$refs.api.post('me/request', params)
      .then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.$bus.emit("update-profile-and-organisation");
          window.scrollTo(0, 0);
        }
      })
      .catch(function (error) {
        self.requestSend = false;
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined')
        {
          self.errors = error.response.data;
        }
      });
    },
    cancelRequest: function() {
      var self = this;
      if (!this.profile.is_admin) return;
      this.requestSend = true;
      this.errors = {};
      var params = {};
      params['role'] = this.roleId;
      params['authority'] = this.organisation.authority;
      this.$root.$refs.api.patch('requests/' + this.id + '/cancel', params)
      .then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.$router.push('/admin/requests');
          return;
        }
      })
      .catch(function (error) {
        self.requestSend = false;
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined')
        {
          self.errors = error.response.data;
        }
      });
    },
    acceptRequest: function() {
      var self = this;
      if (!this.profile.is_admin) return;
      this.requestSend = true;
      this.errors = {};
      var params = {};
      params['role'] = this.roleId;
      params['authority'] = this.organisation.authority;
      this.$root.$refs.api.patch('requests/' + this.id + '/accept', params)
      .then(function (response) {
        if (typeof response.data !== 'undefined' && typeof response.data.uuid !== 'undefined')
        {
          self.$router.push('/admin/organisations/' + response.data.uuid);
          return;
        }
      })
      .catch(function (error) {
        self.requestSend = false;
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined')
        {
          self.errors = error.response.data;
        }
      });
    },
    deleteRequest: function() {
      var self = this;
      if (!this.profile.is_admin) return;
      this.requestSend = true;
      this.errors = {};
      this.$root.$refs.api.delete('requests/' + this.id)
      .then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.$router.push('/admin/requests');
          return;
        }
      })
      .catch(function (error) {
        self.requestSend = false;
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined')
        {
          self.errors = error.response.data;
        }
      });
    }
  },
  computed: {
    organisation() {
      if (this.profile.is_admin)
      {
        return this.org;
      }
      var org = this.$organisation()
      if (!org['country'])
      {
        org['country'] = 'Polska'
      }
      return org;
    },
    profile() {
      return this.$profile();
    }
  },
  watch: {
    organisation: function(val) {
      this.clientType = ( val['internal_client'] ? 'internal' : 'external' );
      if (typeof val['role'] !== 'undefined' && val['role']['id'])
      {
        if (this.clientType == 'internal')
        {
          this.roleId = false;
          for(let i = 0; i < this.rolesInternal.length; i++)
          {
            if (this.rolesInternal[i]['id'] == val['role']['id'])
            {
              this.roleId = val['role']['id'];
              break;
            }
          }
        }
        else
        {
          this.roleId = false;
          for(let i = 0; i < this.rolesExternal.length; i++)
          {
            if (this.rolesExternal[i]['id'] == val['role']['id'])
            {
              this.roleId = val['role']['id'];
              break;
            }
          }
        }
      }
      if (val['application_date'])
      {
        this.$bus.emit("start-organisation-updates");
      }
      else
      {
        this.$bus.emit("stop-organisation-updates");
      }
      this.requestSend = false;
    },
  }
};
</script>
